import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import Photo from '../components/Photo';
import "../styles/credentialspage.scss";
import { navigate } from "gatsby";
import { Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { Link, I18nextContext } from "gatsby-plugin-react-i18next";
import SuccessAlert from '../components/common/SuccessAlert';
import ErrorAlert from '../components/common/ErrorAlert';
import { Helmet } from "react-helmet";
import { updateTenantAdminPassword } from "../services/tenant-identity-service";

const initialFormValue = {
    password: "",
    confirmpassword: ""
}

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;

function CredentialsPage() {
    const { language } = React.useContext(I18nextContext);
    const buildCanonicalUrl = () =>
        language === "en"
            ? GATSBY_LANDING_PAGE_URL + "/credentialspage/"
            : GATSBY_LANDING_PAGE_URL + `/${language}/credentialspage/`

    const canonicalUrl = buildCanonicalUrl();

    const buildHrefUrl = () =>
        language === "en"
            ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
            : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
    const hrefUrl = buildHrefUrl();

    const [alert_type, setAlertType] = useState("");
    const [alert_message, setAlertMessage] = useState('');
    const [formValues, setformValues] = useState(initialFormValue);
    const [passwordMisMatchErr, setPasswordMisMatchErr] = useState("");
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userinfo'));

        //Check userinfo in local storage if it is empty then redirect to home page.
        if (localStorage.getItem('userinfo')) {
            const value = { ...initialFormValue };
            value['password'] = userData.password;
            value['confirmpassword'] = userData.confirmpassword;
        } else {
            let currentLanguage = `${language}`;
            if (currentLanguage === 'en') {
                navigate('/');
            }
            else {
                navigate(`/${currentLanguage}/`);
            }
        }
    }, [language]);

    const handleChange = (event) => {
        const value = { ...formValues };
        value[event.target.name] = event.target.value;
        setformValues(value);
    }

    const validate = () => {
        let passwordMisMatchErr = '';

        if (formValues.password !== formValues.confirmpassword) {
            passwordMisMatchErr = "Password don't match.";
        }

        if (passwordMisMatchErr) {
            setPasswordMisMatchErr(passwordMisMatchErr);
            return false;
        }

        return true;
    }

    const clearAlertType = () => {
        setAlertType("");
        setAlertMessage("");
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        clearAlertType();

        let isValid = validate();

        if (isValid) {
            setPasswordMisMatchErr("");
            setLoading(true);
            let userData = JSON.parse(localStorage.getItem('userinfo'));

            let inputData = {
                "username": userData.email,
                "newpassword": formValues.password
            }

            updateTenantAdminPassword(inputData)
                .then(response => {
                    setLoading(false);
                    let currentLanguage = `${language}`;

                    if (currentLanguage === 'en') {
                        navigate("/dashboard");
                    }
                    else {
                        navigate(`/${currentLanguage}/dashboard`);
                    }
                }).catch(err => {
                    setLoading(false);
                    let errMessage = err.response.data.errorMessage;
                    setAlertType("ERROR");
                    setAlertMessage(errMessage);
                });
        }
    }


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Credentials</title>
                <Link rel="canonical" href={canonicalUrl} />
                <Link rel="alternate" href={hrefUrl} hreflang={language} />
                <meta name="description" content="" />
                <meta name="Keywords" content="web development, companypage" data-react-helmet="true" />
            </Helmet>
            <main class="purchase-page height_100_percent_page" lang={language}>
                <div class="purchase-page-div height_100_percent_page_div" lang={language}>
                    <div class="container">
                        {
                            alert_type === 'SUCCESS' ? (<SuccessAlert message={alert_message} />) : null
                        }

                        {
                            alert_type === 'ERROR' ? (<ErrorAlert message={alert_message} />) : null
                        }

                        <div class="row purchase-total" lang={language}>
                            <div class="col-md-6">
                                <div class="contents ipadcredential">
                                    <Link to="/" language={language}>{" "}<Photo src="LogiPulse-Logo-H48.svg" className="photo-size" alt="" />{" "}</Link>
                                    <p><Trans>Create a SaaS account to have a single helping aid to handle everything from pickup to delivery. Supervise your logistics operations from one platform and expand it out around the world!</Trans></p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="contents">
                                    {/* <!-- multistep form --> */}
                                    <div className="purchase_heading_section">
                                        <div className="profile"><b><Trans>Credentials</Trans></b></div>
                                        <div className="purchase_progress">
                                            <ul id="progressbar_purchase">
                                                <li></li>
                                                <li></li>
                                                <li class="active"></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <Form onSubmit={submitHandler} autoComplete="off">

                                        <Form.Group controlId="formPassword">
                                            <Form.Label><Trans>Choose Password</Trans></Form.Label>
                                            <Form.Control type="password"
                                                name="password"
                                                placeholder="********"
                                                autoFocus
                                                value={formValues.password}
                                                onChange={handleChange} required
                                            />

                                        </Form.Group>

                                        <Form.Group controlId="formConformPassword">
                                            <Form.Label><Trans>Confirm Password</Trans></Form.Label>
                                            <Form.Control type="password"
                                                name="confirmpassword"
                                                placeholder="********"
                                                value={formValues.confirmpassword}
                                                onChange={handleChange} required
                                            />
                                        </Form.Group>


                                        {passwordMisMatchErr ? (
                                            <div style={{ color: "red", fontSize: "12px" }}>{passwordMisMatchErr}</div>
                                        ) : null}

                                        <div className="password_policy">Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & symbols</div>

                                        <Form.Group className="next_button_align" controlId="formBasicCheckbox">
                                            <button type="submit" className="submit_button_common font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative margin_left_auto" ><Trans>NEXT</Trans>
                                                {
                                                    isLoading ? (
                                                        <Photo src="loader_64.gif" className="spinner" alt="" />
                                                    ) : null
                                                }
                                            </button>
                                        </Form.Group>

                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="purchase-footer">
                    <div class="purchase-footer-bottom">
                        <div class="container">

                            <div className="row align-content-center">
                                <div class="col-md-4 power">
                                    <div><Trans>Powered by</Trans></div>
                                    <Photo src="Stellosysyw-Logo.svg" alt="" className="powered_logo" />
                                </div>
                                <div class="col-md-4 copyright">
                                    <div>
                                        <Trans>Copyright 2021 Stellosys</Trans><br /><Trans> All rights Reserved.</Trans>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </footer>
            </main>
        </div>
    )
}

export default CredentialsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`